@import url(https://fonts.googleapis.com/css2?family=Lora:ital@0;1&family=Rajdhani&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Lora:ital@0;1&family=Rajdhani&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Lora:ital@0;1&family=Nunito:wght@200&family=Rajdhani&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Lora:ital@0;1&family=Nunito:wght@200&family=Rajdhani&family=Roboto:wght@300&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Rajdhani&display=swap'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Rajdhani';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  font-family: 'Lora';
}

.bioPage {
  display: flex;
}



.testing {
  align-items: center;
    color: green;
    inset-inline: 16px 56px;
    inset-inline-start: 16px;
    inset-inline-end: 56px;
    bottom: 32px;
    display: flex;
    font-size: .875rem;
    position: absolute;
    transition: opacity .4s;
    width: auto;
    z-index: 1;
}

/* @keyframes slide-in {
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
} */


.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(255,102,203);
} 


@media only screen and (max-width: 600px) {
  .bioPage {
    display: block;
  }
}
.mediaPage {
  display: flex;
  margin-bottom: 0;
}

.discriptionWeb {
  text-align: center;
  margin: 7px;
  padding: 7px;
  line-height: 2.5em;
  flex: 6 1;
}

.videoWebStyle {
  width: 100%; 
  height: 400px; 
  box-shadow: 5px 5px 5px #9E9E9E;
}

.formStyle {
  display: flex;
  flex: 6 1;
  width: 50%;
  padding: 20px;
  margin: auto;
  border: 2px solid #AD974F;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #eee;
  
}

.paragraphStyle {
  font-family: Roboto;
  font-weight: 900;
  display: flex;
  flex: 6 1;
  width: 50%;
  margin: 19px auto;
  size: 14px;
  color: #5C5E62;
}
.blogReadMore {
  position: absolute; 
  width: 100%; 
  bottom: -15%; 
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .mediaPage {
    /* display: block; */
    flex-direction: column;
    margin: 0px;
    padding: 6px;
    width: 100%;
  }
  .discriptionWeb {
    margin-top: 15px;
    
  }
  .videoWebStyle {
    height: 275px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mediaReverse {
    flex-direction: column-reverse;
  }
  .formStyle {
    width: 95%;
  }
  .paragraphStyle {
    width: 100%;
  }
  .blogDate {
    display: none !important; 
  }
  .blogDateMobile {
    display: block !important; 
  }
  .blogReadMore {

    bottom: -10%; 
  }
}
  
.HoverClass1:hover {
  color: rgb(255, 238, 194) !important;
}

.conRdg:hover {
  color: #EAEAEA;
  font-weight: bold;
}

.conRdgBorder:hover {
  border: 3px solid #9E9E9E !important;
  background-color: #8E793E!important;
}
.alert{align-items:center;-webkit-animation:.5s ease-in-out 0s 1 light-bounce-in;animation:.5s ease-in-out 0s 1 light-bounce-in;bottom:1rem;display:flex;left:0;margin:auto;max-width:30rem;position:fixed;right:0;z-index:10000}.alert .alert-body{margin-bottom:0}.close:active,.close:focus{outline:none}@-webkit-keyframes light-bounce-in{0%{opacity:0;-webkit-transform:translateY(20%);transform:translateY(20%)}50%{-webkit-transform:translateY(-5%);transform:translateY(-5%)}100%{opacity:1;-webkit-transform:translateY(0%);transform:translateY(0%)}}@keyframes light-bounce-in{0%{opacity:0;-webkit-transform:translateY(20%);transform:translateY(20%)}50%{-webkit-transform:translateY(-5%);transform:translateY(-5%)}100%{opacity:1;-webkit-transform:translateY(0%);transform:translateY(0%)}}
